@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Source+Sans+Pro:ital,wght@0,900;1,900&display=swap'); */

:root{
  --typing-box-width: 550px;
  --typing-box-height: 375px;
  --text-font: 20px;
  --text-inner-padding: 20px;
}
/* #e8e8e8 */
html{
  -webkit-user-select: none;
          user-select: none;
  --cursor-color: #8929ff9a;
  --error-color: rgb(255, 52, 52);
}

html[theme="light"][neumorphism="true"]{
  --page-color: #e8e8e8;
  --shade-1: #ececec;
  --shade-2: #dfdfdf;
  --box-shadow: -5px -5px 10px rgba(255, 255, 255, 1), 5px 5px 10px rgba(0, 0, 0, 0.2);
  --text-color: #414141;
  --box-shadow-inset: inset -2px -2px 5px rgb(255, 255, 255),
                      inset 2px 2px 5px rgba(0, 0, 0, 0.15);
  --settings-box-shadow: -10px -10px 10px rgba(255, 255, 255, 1), 10px 10px 10px rgba(0, 0, 0, 0.2);
  --box-shadow-gear: -8px -8px 10px rgba(255, 255, 255, 1), 6px 6px 10px rgba(0, 0, 0, 0.2);
  --box-shadow-gear-inset: inset 8px 8px 10px rgba(255, 255, 255, 0.3), inset -8px -8px 10px rgba(0, 0, 0, 0.3);
  --purple-shade-1: #8929ff9a;
  --box-shadow-settings-option: 0px 0px 5px rgba(0, 0, 0, 0.2);
  --color-hover: rgb(90, 90, 90);
  --options-color: #ececec;
  --icon-color-hover: #505050;
  --box-color: rgb(195, 195, 195);
  --title-color: #a5a5a5;
  --account-text-color: var(--title-color);

}

html[theme="dark"][neumorphism="true"]{
  --page-color: rgb(28, 28, 28);
  --shade-1: rgb(30, 30, 30);
  --box-shadow: -3px -3px 6px rgba(255, 255, 255, 0.07), 5px 5px 10px rgba(0, 0, 0, 0.5);
  --text-color: white;
  --box-shadow-inset: inset 2px 2px 5px rgb(0, 0, 0, 0.7),
  inset -2px -2px 5px rgba(255, 255, 255, 0.15);
  --box-shadow-gear: -5px -5px 10px rgba(255, 255, 255, 0.12), 6px 6px 10px rgba(0, 0, 0, 0.35);
  --box-shadow-gear-inset: inset 8px 8px 10px rgba(255, 255, 255, 0.2), inset -8px -8px 10px rgba(0, 0, 0, 0.4);
  --purple-shade-1: #ad6bff9a;
  --purple-shade-2: #3a22589a;
  --box-shadow-settings-option: none;
  --color-hover: rgba(255, 255, 255, 0.8);
  --options-color: #ececec;
  --icon-color-hover: #c5c5c5;
  --box-color: rgb(65, 65, 65);
  --title-color: #4b4b4b;
  --account-text-color: #bfbfbf;
}

html[theme="light"][neumorphism="false"]{
  --page-color: #e8e8e8;
  --shade-1: #d8d8d8;
  --box-shadow: none;
  --text-color: #414141;
  --box-shadow-inset: none;
  --settings-box-shadow: none;
  --box-shadow-gear: none;
  --box-shadow-gear-inset: none;
  --purple-shade-1: #8929ff9a;
  --box-shadow-settings-option: none;
  --color-hover: rgb(90, 90, 90);
  --options-color: #ececec;
  --icon-color-hover: #505050;
  --box-color: rgb(195, 195, 195);
  --title-color: #a5a5a5;
  --account-text-color: var(--title-color);
  
}

html[theme="dark"][neumorphism="false"]{
  --page-color: rgb(28, 28, 28);
  --shade-1: rgb(36, 36, 36);
  --box-shadow: none;
  --text-color: white;
  --box-shadow-inset: none;
  --box-shadow-gear: none;
  --box-shadow-gear-inset: none;
  --purple-shade-1: #ad6bff9a;
  --purple-shade-2: #3a22589a;
  --box-shadow-settings-option: none;
  --color-hover: rgba(255, 255, 255, 0.8);
  --options-color: #ececec;
  --icon-color-hover: #c5c5c5;
  --box-color: rgb(65, 65, 65);
  --title-color: #4b4b4b;
  --account-text-color: #bfbfbf;
}

body {
  font-family: 'Roboto Mono', monospace;
  background-color: var(--page-color);
}

.minibar-container{
  z-index: 3;
  position: fixed;
  right: 25px;
  bottom: 25px;
  width: 45px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.minibar-container > div{
  color: var(--icon-color);
  background: none;
  border: none;
}

.minibar-container > div:hover{
  color: var(--icon-color-hover);
  transition: color 0.2s;
}

.minibar-container > button{
  color: var(--icon-color);
  background: none;
  border: none;
}

.minibar-container > button:hover{
  color: var(--icon-color-hover);
  transition: color 0.2s;
}

.title{
  height: 150px;
  line-height: 50%;
  font-size: 70px;
  display: flex;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 900;
  color: var(--title-color);
}

@media screen and (max-width: 670px){
  .title > p{
    font-size: 30px;
  }
  .title{
    margin-left: -20px;
    margin-top: -20px;
  }
  :root{
    --typing-box-width: 298px;
    --typing-box-height: 200px;
    --text-font: 12px;
    --text-inner-padding: 13px;
  }
  .minibar-container{
    width: 35px;
    height: 100px;
    right: 10px;
    bottom: 10px;
  }

}

.coffee-container{
  margin-left: 100px;
}

.main-content{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* height: 500px; */
  margin-top: 10vh;
  display: flex;
  flex-flow: column;
}

.typing-box{
  -webkit-user-select: text;
          user-select: text;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 1.7%;
  background-color: var(--shade-1);
  box-shadow: var(--box-shadow);
  margin-bottom: 10px;
}

.typing-text{
  padding: 20px;
  padding: var(--text-inner-padding);
  position: relative;
  width: calc(550px - 40px);
  width: calc(var(--typing-box-width) - 40px);
  height: auto;
  font-size: 20px;
  font-size: var(--text-font);
  color: var(--text-color);
}


.title-button{
  display: inline-block;
}

.title-button:link, .title-button:visited{
  margin-left: 50px;
  color: var(--icon-color);
}

.title-button:hover{
  color: var(--icon-color-hover);
  transition: color 0.2s;
}

a{
  color: var(--icon-color);
}
a:hover{
  color: var(--icon-color-hover);
  transition: color 0.2s;
}
a:link:active, a:visited:active{
  color: var(--icon-color);
}

.title-buttons-container{
  margin-left: 130px;
}


html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 100ms !important;
  transition-delay: 0 !important;
}

@media only screen and (min-width: 1700px){
  :root{
    --typing-box-width: 660px;
    --typing-box-height: 450px;
    --text-font: 25px;
    --text-inner-padding: 23px;
  }
  
}

@media only screen and (max-width: 1700px){
  .title{
    height: 130px;
    font-size: 55px;
  }
  .main-content{
    /* margin-top: 0px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .title-buttons-container{
    margin-left: 80px;
  }
}

@media screen and (max-width: 760px){
  .title-buttons-container{
    display: none;
  }
}
@-webkit-keyframes show-settings{
    from{
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        
    }
    to{
        background: rgba( 255, 255, 255, 0.02 );
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(4px);
    }
}

@keyframes show-settings{
    from{
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        
    }
    to{
        background: rgba( 255, 255, 255, 0.02 );
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(4px);
    }
}

@-webkit-keyframes hide-settings{
    from{
        /* background: rgba( 255, 255, 255, 0.15 ); */
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(4px);
        
    }
    to{
        background-color: transparent;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        z-index: -1;
        
    }
}

@keyframes hide-settings{
    from{
        /* background: rgba( 255, 255, 255, 0.15 ); */
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(4px);
        
    }
    to{
        background-color: transparent;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        z-index: -1;
        
    }
}

@-webkit-keyframes slide-in-options{
    from{
        opacity: 0;
        width: 100vw;
    }
    to{
        opacity: 1;
        width: 700px;
        width: var(--settings-options-width);
    }
}

@keyframes slide-in-options{
    from{
        opacity: 0;
        width: 100vw;
    }
    to{
        opacity: 1;
        width: 700px;
        width: var(--settings-options-width);
    }
}

@-webkit-keyframes slide-out-options{
    from{
        opacity: 1;
        width: 700px;
        width: var(--settings-options-width);
    }
    to{
        opacity: 0;
        width: 100vw;
    }
}

@keyframes slide-out-options{
    from{
        opacity: 1;
        width: 700px;
        width: var(--settings-options-width);
    }
    to{
        opacity: 0;
        width: 100vw;
    }
}

:root{
    --settings-options-width: 700px;
}

@media screen and (max-width: 670px){
    :root{
        --settings-options-width: 335px;
    }
}

.settings-button-container{
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: #BB86FC;
    box-shadow: var(--box-shadow-gear);
    z-index: 3;
}

.gear{
    width: 85%;
    height: 85%;
    color: whitesmoke;
}

.gear[isopen="true"]{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.gear[isopen="false"]{
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.settings-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: -1;
}

.settings-container[isopen="true"]{
    z-index: 1;
    -webkit-animation: show-settings 0.3s;
            animation: show-settings 0.3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.settings-container[isopen="false"]{
    z-index: 1;
    -webkit-animation: hide-settings 0.3s;
            animation: hide-settings 0.3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.settings-options{
    width: 700px;
    width: var(--settings-options-width);
    display: flex;
    justify-content: flex-end;
}

.settings-options[isopen="true"]{
    -webkit-animation: slide-in-options 0.3s;
            animation: slide-in-options 0.3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.settings-options[isopen="false"]{
    -webkit-animation: slide-out-options 0.3s;
            animation: slide-out-options 0.3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.settings-option-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(700px/2);
    width: calc(var(--settings-options-width)/2);
}

.word{
  display: inline-block;
}

.letter{
  display: inline-block;
  /* margin: 1px; */
}

.letter-active{
  display: inline-block;
  background-color: var(--cursor-color);
  /* margin: 1px; */
  border-radius: 2px;
  /* color: rgb(119, 119, 119); */
  /* animation: cursorBlinking 0.5s infinite; */
}

@-webkit-keyframes cursorBlinking{
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes cursorBlinking{
  from { opacity: 1; }
  to { opacity: 0; }
}

.letter-error{
  display: inline-block;
  /* margin: 1px; */
  /* border-radius: 1px; */
  background-color: var(--error-color);
}

.letter-finished{
  display: inline-block;
  background-color: gray;
}
:root{
    --ui-color: rgb(128, 128, 128);
    --checkbox-size: 20px;
    --option-box-width: 220px;
    --option-font: 25px;
}

.option-box-container{
    width: 220px;
    width: var(--option-box-width);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 17px;
}

.option-title{
    font-family: 'Roboto', sans-serif;

    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 20px;
    font-size: 25px;
    font-size: var(--option-font);
    font-weight: 800;

    color: var(--text-color);
}

@media only screen and (min-width: 1300px){
    :root{
        --option-box-width: 300px;
        --option-font: 38px;
    }
}
:root{
    --ui-color: rgb(128, 128, 128);
    --checkbox-size: 20px;
    --description-font-size: 16px;
}

@media screen and (max-width: 670px) {
    :root{
        --checkbox-size: 15px;
        --description-font-size: 12px;
    }
}

.toggle-container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 20px;
    padding-bottom: 15px;

    color: var(--text-color);
}

.label-container{
    display: flex;
    align-items: center;
    color: var(--settings-text-color);
}

.description-container{
    margin-left: 20px;
    font-size: 16px;
    font-size: var(--description-font-size);
}

input[type=checkbox] {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
}

.custom-toggle{
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 20px;
    width: var(--checkbox-size);
    height: 20px;
    height: var(--checkbox-size);
    border-radius: 4px;
    background-color: transparent;
    border: 2px solid var(--text-color);
}

/* hover */
.label-container:hover input ~ .custom-toggle {
    border: 2px solid var(--color-hover);
}

/* click */
.label-container input:active ~ .custom-toggle {
    -webkit-animation: border-click 0.1s;
            animation: border-click 0.1s;
    
}
  
/* checked */
.label-container input:checked ~ .custom-toggle {
    /* background-color: green; */
}

.custom-toggle:after {
    content: "";
    position: absolute;
    display: none;
}

.label-container input:checked ~ .custom-toggle:after {
    display: block;
}
  
/* checkmark */
.label-container .custom-toggle:after {
    width: 80%;
    height: 80%;
    border-radius: 2px;
    -webkit-animation: checkmark-grow 0.2s;
            animation: checkmark-grow 0.2s;
    background-color: var(--text-color);
}

@media only screen and (min-width: 1300px){
    :root{
        --description-font-size: 20px;
    }
    .custom-color-button{
        font-size: 16px;
        font-size: var(--description-font-size);
    }
}

@-webkit-keyframes checkmark-grow{
    0%{
        width: 0px;
        height: 0px
    }
    50%{
        width: 0px;
        height: 0px;
    }
    80%{
        width: 100%;
        height: 100%;
    }
    100%{
        width: 80%;
        height: 80%;
    }
}

@keyframes checkmark-grow{
    0%{
        width: 0px;
        height: 0px
    }
    50%{
        width: 0px;
        height: 0px;
    }
    80%{
        width: 100%;
        height: 100%;
    }
    100%{
        width: 80%;
        height: 80%;
    }
}

@-webkit-keyframes border-click{
    0%{
        width: 20px;
        width: var(--checkbox-size);
        height: 20px;
        height: var(--checkbox-size);
    }
    100%{
        margin-left: 1px;
        margin-top: 1px;
        width: calc(20px - 2px);
        width: calc(var(--checkbox-size) - 2px);
        height: calc(20px - 2px);
        height: calc(var(--checkbox-size) - 2px);
    }
}

@keyframes border-click{
    0%{
        width: 20px;
        width: var(--checkbox-size);
        height: 20px;
        height: var(--checkbox-size);
    }
    100%{
        margin-left: 1px;
        margin-top: 1px;
        width: calc(20px - 2px);
        width: calc(var(--checkbox-size) - 2px);
        height: calc(20px - 2px);
        height: calc(var(--checkbox-size) - 2px);
    }
}

/* @keyframes border-release{
    0%{
        width: calc(var(--checkbox-size) - 2px);
        height: calc(var(--checkbox-size) - 2px);
    }
    100%{
        width: var(--checkbox-size);
        height: var(--checkbox-size);
    }
} */
:root{
    --color-width: 27px;
    --color-height: 25px;
}

.palette-container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-bottom: 25px;
}

.color-container > input[type=checkbox] {
    position: absolute;
}

.color-container{
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}

.custom-color-toggle{
    margin-bottom: calc(-1*25px + 2px);
    margin-bottom: calc(-1*var(--color-height) + 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    width: 27px;
    width: var(--color-width);
    height: 25px;
    height: var(--color-height);
    border-radius: 4px;
    background-color: transparent;
    border: 2px solid transparent;
}

/* hover */
.color-container:hover input ~ .custom-color-toggle {
    border: 2px solid var(--color-hover);
}

/* click */
.color-container input:active ~ .custom-color-toggle {
    
}
  
/* checked */
.color-container input:checked ~ .custom-color-toggle {
    border: 2px solid var(--ui-color);
    /* background-color: green; */
}

.custom-toggle:after {
    content: "";
    position: absolute;
    display: none;
}

.color-container input:checked ~ .custom-color-toggle:after {
    display: block;
}
  
/* checkmark */
.color-container .custom-color-toggle:after {
    background-color: var(--ui-color);
}

.color-picker-container{
    /* padding-left: 20px;
    padding-bottom: 30px; */
}

.custom-color-container{
    padding-left: 20px;
    padding-bottom: 15px;
}

.custom-color-button{
    line-height: 30px;
    display: flex;
    color: var(--text-color);
    justify-content: space-between;
    background-color: transparent;
    border: none;
    width: 110px;

    height: 37px;
    border-radius: 4px;
    border: 2px solid transparent;
}

.custom-color-button:hover{
    cursor: pointer;
}
.color-picker-container{
    position: absolute;
    width: 220px;
    height: 100px;
    background-color: transparent;
}
:root{
  --hover-color: rgba(255, 255, 255, 0.13);
  --icon-color: #868686;
}

.header-container{
  display: flex;
  justify-content: space-between;

  margin-bottom: 10px;
}

.header-buttons-container{
  display: flex;
  justify-self: space-between;
}

.stats-container{
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
}

.paste-container, .next-container, .restart-container{
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
  background-color: var(--shade-1);
  border-radius: 6px;
  box-shadow: var(--box-shadow);
}
.paste-container:hover, .next-container:hover, .restart-container:hover{
  width: 57px;
  height: 57px;
  border: 1.5px solid var(--shade-1);
  box-shadow: var(--box-shadow), var(--box-shadow-inset);
  /* transition: box-shadow 0.4s; */
}

.paste-container:active, .next-container:active, .restart-container:active{
  box-shadow: var(--box-shadow-inset);
}

/* .paste-container:hover{
  background-color: var(--hover-color);
  transition: background-color 0.2s;
} */

/* .next-container:hover{
  background-color: var(--hover-color);
  transition: background-color 0.2s;
} */

.wpm-container{
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* font-weight: 600; */
  font-size: 14px;
  color: var(--text-color);
  /* background-color: gray; */
  /* width: 85px; */
  display: inline-block;
}

.accuracy-container{
  font-family: 'Montserrat', sans-serif;
  /* font-weight: 600; */
  font-size: 14px;
  color: var(--text-color);
  /* background-color: gray; */
  /* width: 100px; */
  display: inline-block;
  padding-right: 25px;
}

.wpm{
  background-color: var(--shade-1);
  margin-top: -15px;
  margin-left: 40px;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;

  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  box-shadow: var(--box-shadow);
  
}

.accuracy{
  background-color: var(--shade-1);
  margin-top: -15px;
  margin-left: 65px;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;

  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  box-shadow: var(--box-shadow);
}

.next-container, .restart-container{
  margin-left: 15px;
}

@media screen and (max-width: 670px){
  .accuracy-container, .wpm-container{
    font-size: 8px;
  }
  .accuracy-container{
    padding-right: 10px;
  }
  .next-container, .restart-container{
    margin-left: 8px;
  }
  .accuracy, .wpm{
    line-height: 35px;
    width: 35px;
    height: 35px;
    font-size: 12px;
  }
  .accuracy{
    margin-left: 40px;
  }
  .wpm{
    margin-left: 28px;
  }

  .paste-container, .next-container, .restart-container{
    width: 35px;
    height: 35px;
    border-radius: 4px;
  }
  .paste-container:hover, .next-container:hover, .restart-container:hover{
    width: 32px;
    height: 32px;
  }
}
.box-container{
    border-radius: 6px;
    background-color: var(--shade-1);
    box-shadow: var(--box-shadow);
    
    display: flex;
    justify-content: center;
}

.input-box{
    width: 93%;
    justify-content: space-between;
    margin: 17px;
    height: 30px;
    border: 2px solid var(--box-color);
    border-radius: 4px;
    background-color: transparent;
    font-size: var(--text-font);
    caret-color: var(--text-color);
    color: var(--text-color);
}

@media screen and (max-width: 670px){
    .input-box{
        height: 20px;
    }
}
.login-page{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    font-family: 'Source Sans Pro', sans-serif;
}

.login-page > p{
    color: var(--text-color);
}
/* @media (max-width: 1024px){
    .login-page{
        width: 500px;
    }
} */

@media (max-width: 768px){
    .login-page{
        flex-direction: column-reverse;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .login-page div{
        padding: 20px;
    }
}

.fields{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
}

.login-page button{
    background-color: transparent;
    border: none;
    color: var(--text-color);
    font-weight: 500;
    font-size: 20px;
    margin: 20px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 3px;
}
.login-page button:hover{
    background-color: #7f7f7f;
    color: rgb(230, 230, 230);
    transition: background-color 0.2s;
}


input{
    height: 30px;
    margin: 5px;
    padding-left: 8px;
    border-radius: 8px;
    background-color: var(--title-color);
    border: 2px solid rgb(91, 91, 91);
    outline: none;
    caret-color: purple;
    color: var(--text-color);
    font-size: 16px;
}
input::-webkit-input-placeholder{
    color: rgb(94, 94, 94);
}
input::placeholder{
    color: rgb(94, 94, 94);
}
.account-container{
    margin-top: -10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1280px;
    padding: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 40px;
    color: var(--text-color);
}

.stats{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 1024px;
    /* align-self: flex-start; s*/
}

.stats > div > .text{
    font-size: 20px;
    color: var(--text-color);
    /* align-self: flex-start; */
    /* display: inline-block; */
}

.text > .stat{
    font-size: 35px;
    font-weight: 600;
    display: inline-block;
    padding: 2px;
}

.graph-container{
    width: 1024px;
    /* width: 768px; */   
}

.graph{
    padding-right: 20px;
    padding-left: 20px
}

.account-container button{
    background-color: transparent;
    border: none;
    margin: 30px;
    margin-right: 50px;
    color: var(--text-color);
    font-weight: 600;
    font-size: 20px;
    align-self: flex-end;
    padding: 8px;
    border-radius: 3px;
}

.account-container button:hover{
    background-color: #7f7f7f;
    color: rgb(230, 230, 230);
    transition: background-color 0.2s;
}

.account-container .username{
    padding-bottom: 75px;
    align-self: flex-start;
}

.stats-line{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
}

@media (max-width: 1280px){
    .account-container{
        width: 1024px;
    }
}

@media (max-width: 1024px){
    .account-container{
        width: 640px;
    }
    .stats{
        width: 640px;
    }
    .graph-container{
        width: 640px;
    }
    .graph{
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media (max-width: 670px){
    .stats{
        align-self: center;
        align-items: center;
        justify-content: center;
    }
    .stats-line{
        flex-direction: column;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .account-container > .text{
        align-self: center;
    }
    .graph-container{
        width: 500px;
    }
    .account-container{
        font-size: 20px;
    }
    .account-container > button{
        align-self: center;
    }
    .account-container > .username{
        align-self: center;
    }
}
/* .graph{
    width: 600px;
} */
