.login-page{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    font-family: 'Source Sans Pro', sans-serif;
}

.login-page > p{
    color: var(--text-color);
}
/* @media (max-width: 1024px){
    .login-page{
        width: 500px;
    }
} */

@media (max-width: 768px){
    .login-page{
        flex-direction: column-reverse;
        width: fit-content;
    }
    .login-page div{
        padding: 20px;
    }
}

.fields{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
}

.login-page button{
    background-color: transparent;
    border: none;
    color: var(--text-color);
    font-weight: 500;
    font-size: 20px;
    margin: 20px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 3px;
}
.login-page button:hover{
    background-color: #7f7f7f;
    color: rgb(230, 230, 230);
    transition: background-color 0.2s;
}


input{
    height: 30px;
    margin: 5px;
    padding-left: 8px;
    border-radius: 8px;
    background-color: var(--title-color);
    border: 2px solid rgb(91, 91, 91);
    outline: none;
    caret-color: purple;
    color: var(--text-color);
    font-size: 16px;
}
input::placeholder{
    color: rgb(94, 94, 94);
}