@keyframes show-settings{
    from{
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        
    }
    to{
        background: rgba( 255, 255, 255, 0.02 );
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(4px);
    }
}

@keyframes hide-settings{
    from{
        /* background: rgba( 255, 255, 255, 0.15 ); */
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(4px);
        
    }
    to{
        background-color: transparent;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        z-index: -1;
        
    }
}

@keyframes slide-in-options{
    from{
        opacity: 0;
        width: 100vw;
    }
    to{
        opacity: 1;
        width: var(--settings-options-width);
    }
}

@keyframes slide-out-options{
    from{
        opacity: 1;
        width: var(--settings-options-width);
    }
    to{
        opacity: 0;
        width: 100vw;
    }
}

:root{
    --settings-options-width: 700px;
}

@media screen and (max-width: 670px){
    :root{
        --settings-options-width: 335px;
    }
}

.settings-button-container{
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: #BB86FC;
    box-shadow: var(--box-shadow-gear);
    z-index: 3;
}

.gear{
    width: 85%;
    height: 85%;
    color: whitesmoke;
}

.gear[isopen="true"]{
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
}

.gear[isopen="false"]{
    transform: rotate(-90deg);
    transition: transform 0.3s ease-in-out;
}

.settings-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: -1;
}

.settings-container[isopen="true"]{
    z-index: 1;
    animation: show-settings 0.3s;
    animation-fill-mode: forwards;
}

.settings-container[isopen="false"]{
    z-index: 1;
    animation: hide-settings 0.3s;
    animation-fill-mode: forwards;
}

.settings-options{
    width: var(--settings-options-width);
    display: flex;
    justify-content: flex-end;
}

.settings-options[isopen="true"]{
    animation: slide-in-options 0.3s;
    animation-fill-mode: forwards;
}

.settings-options[isopen="false"]{
    animation: slide-out-options 0.3s;
    animation-fill-mode: forwards;
}

.settings-option-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(var(--settings-options-width)/2);
}
