@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

:root{
    --ui-color: rgb(128, 128, 128);
    --checkbox-size: 20px;
    --description-font-size: 16px;
}

@media screen and (max-width: 670px) {
    :root{
        --checkbox-size: 15px;
        --description-font-size: 12px;
    }
}

.toggle-container{
    width: fit-content;
    height: fit-content;
    padding-left: 20px;
    padding-bottom: 15px;

    color: var(--text-color);
}

.label-container{
    display: flex;
    align-items: center;
    color: var(--settings-text-color);
}

.description-container{
    margin-left: 20px;
    font-size: var(--description-font-size);
}

input[type=checkbox] {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
}

.custom-toggle{
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    border-radius: 4px;
    background-color: transparent;
    border: 2px solid var(--text-color);
}

/* hover */
.label-container:hover input ~ .custom-toggle {
    border: 2px solid var(--color-hover);
}

/* click */
.label-container input:active ~ .custom-toggle {
    animation: border-click 0.1s;
    
}
  
/* checked */
.label-container input:checked ~ .custom-toggle {
    /* background-color: green; */
}

.custom-toggle:after {
    content: "";
    position: absolute;
    display: none;
}

.label-container input:checked ~ .custom-toggle:after {
    display: block;
}
  
/* checkmark */
.label-container .custom-toggle:after {
    width: 80%;
    height: 80%;
    border-radius: 2px;
    animation: checkmark-grow 0.2s;
    background-color: var(--text-color);
}

@media only screen and (min-width: 1300px){
    :root{
        --description-font-size: 20px;
    }
    .custom-color-button{
        font-size: var(--description-font-size);
    }
}

@keyframes checkmark-grow{
    0%{
        width: 0px;
        height: 0px
    }
    50%{
        width: 0px;
        height: 0px;
    }
    80%{
        width: 100%;
        height: 100%;
    }
    100%{
        width: 80%;
        height: 80%;
    }
}

@keyframes border-click{
    0%{
        width: var(--checkbox-size);
        height: var(--checkbox-size);
    }
    100%{
        margin-left: 1px;
        margin-top: 1px;
        width: calc(var(--checkbox-size) - 2px);
        height: calc(var(--checkbox-size) - 2px);
    }
}

/* @keyframes border-release{
    0%{
        width: calc(var(--checkbox-size) - 2px);
        height: calc(var(--checkbox-size) - 2px);
    }
    100%{
        width: var(--checkbox-size);
        height: var(--checkbox-size);
    }
} */