.word{
  display: inline-block;
}

.letter{
  display: inline-block;
  /* margin: 1px; */
}

.letter-active{
  display: inline-block;
  background-color: var(--cursor-color);
  /* margin: 1px; */
  border-radius: 2px;
  /* color: rgb(119, 119, 119); */
  /* animation: cursorBlinking 0.5s infinite; */
}

@keyframes cursorBlinking{
  from { opacity: 1; }
  to { opacity: 0; }
}

.letter-error{
  display: inline-block;
  /* margin: 1px; */
  /* border-radius: 1px; */
  background-color: var(--error-color);
}

.letter-finished{
  display: inline-block;
  background-color: gray;
}