@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root{
  --hover-color: rgba(255, 255, 255, 0.13);
  --icon-color: #868686;
}

.header-container{
  display: flex;
  justify-content: space-between;

  margin-bottom: 10px;
}

.header-buttons-container{
  display: flex;
  justify-self: space-between;
}

.stats-container{
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
}

.paste-container, .next-container, .restart-container{
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
  background-color: var(--shade-1);
  border-radius: 6px;
  box-shadow: var(--box-shadow);
}
.paste-container:hover, .next-container:hover, .restart-container:hover{
  width: 57px;
  height: 57px;
  border: 1.5px solid var(--shade-1);
  box-shadow: var(--box-shadow), var(--box-shadow-inset);
  /* transition: box-shadow 0.4s; */
}

.paste-container:active, .next-container:active, .restart-container:active{
  box-shadow: var(--box-shadow-inset);
}

/* .paste-container:hover{
  background-color: var(--hover-color);
  transition: background-color 0.2s;
} */

/* .next-container:hover{
  background-color: var(--hover-color);
  transition: background-color 0.2s;
} */

.wpm-container{
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* font-weight: 600; */
  font-size: 14px;
  color: var(--text-color);
  /* background-color: gray; */
  /* width: 85px; */
  display: inline-block;
}

.accuracy-container{
  font-family: 'Montserrat', sans-serif;
  /* font-weight: 600; */
  font-size: 14px;
  color: var(--text-color);
  /* background-color: gray; */
  /* width: 100px; */
  display: inline-block;
  padding-right: 25px;
}

.wpm{
  background-color: var(--shade-1);
  margin-top: -15px;
  margin-left: 40px;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;

  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  box-shadow: var(--box-shadow);
  
}

.accuracy{
  background-color: var(--shade-1);
  margin-top: -15px;
  margin-left: 65px;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;

  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  box-shadow: var(--box-shadow);
}

.next-container, .restart-container{
  margin-left: 15px;
}

@media screen and (max-width: 670px){
  .accuracy-container, .wpm-container{
    font-size: 8px;
  }
  .accuracy-container{
    padding-right: 10px;
  }
  .next-container, .restart-container{
    margin-left: 8px;
  }
  .accuracy, .wpm{
    line-height: 35px;
    width: 35px;
    height: 35px;
    font-size: 12px;
  }
  .accuracy{
    margin-left: 40px;
  }
  .wpm{
    margin-left: 28px;
  }

  .paste-container, .next-container, .restart-container{
    width: 35px;
    height: 35px;
    border-radius: 4px;
  }
  .paste-container:hover, .next-container:hover, .restart-container:hover{
    width: 32px;
    height: 32px;
  }
}