@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Source+Sans+Pro:ital,wght@0,900;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');

:root{
  --typing-box-width: 550px;
  --typing-box-height: 375px;
  --text-font: 20px;
  --text-inner-padding: 20px;
}
/* #e8e8e8 */
html{
  user-select: none;
  --cursor-color: #8929ff9a;
  --error-color: rgb(255, 52, 52);
}

html[theme="light"][neumorphism="true"]{
  --page-color: #e8e8e8;
  --shade-1: #ececec;
  --shade-2: #dfdfdf;
  --box-shadow: -5px -5px 10px rgba(255, 255, 255, 1), 5px 5px 10px rgba(0, 0, 0, 0.2);
  --text-color: #414141;
  --box-shadow-inset: inset -2px -2px 5px rgb(255, 255, 255),
                      inset 2px 2px 5px rgba(0, 0, 0, 0.15);
  --settings-box-shadow: -10px -10px 10px rgba(255, 255, 255, 1), 10px 10px 10px rgba(0, 0, 0, 0.2);
  --box-shadow-gear: -8px -8px 10px rgba(255, 255, 255, 1), 6px 6px 10px rgba(0, 0, 0, 0.2);
  --box-shadow-gear-inset: inset 8px 8px 10px rgba(255, 255, 255, 0.3), inset -8px -8px 10px rgba(0, 0, 0, 0.3);
  --purple-shade-1: #8929ff9a;
  --box-shadow-settings-option: 0px 0px 5px rgba(0, 0, 0, 0.2);
  --color-hover: rgb(90, 90, 90);
  --options-color: #ececec;
  --icon-color-hover: #505050;
  --box-color: rgb(195, 195, 195);
  --title-color: #a5a5a5;
  --account-text-color: var(--title-color);

}

html[theme="dark"][neumorphism="true"]{
  --page-color: rgb(28, 28, 28);
  --shade-1: rgb(30, 30, 30);
  --box-shadow: -3px -3px 6px rgba(255, 255, 255, 0.07), 5px 5px 10px rgba(0, 0, 0, 0.5);
  --text-color: white;
  --box-shadow-inset: inset 2px 2px 5px rgb(0, 0, 0, 0.7),
  inset -2px -2px 5px rgba(255, 255, 255, 0.15);
  --box-shadow-gear: -5px -5px 10px rgba(255, 255, 255, 0.12), 6px 6px 10px rgba(0, 0, 0, 0.35);
  --box-shadow-gear-inset: inset 8px 8px 10px rgba(255, 255, 255, 0.2), inset -8px -8px 10px rgba(0, 0, 0, 0.4);
  --purple-shade-1: #ad6bff9a;
  --purple-shade-2: #3a22589a;
  --box-shadow-settings-option: none;
  --color-hover: rgba(255, 255, 255, 0.8);
  --options-color: #ececec;
  --icon-color-hover: #c5c5c5;
  --box-color: rgb(65, 65, 65);
  --title-color: #4b4b4b;
  --account-text-color: #bfbfbf;
}

html[theme="light"][neumorphism="false"]{
  --page-color: #e8e8e8;
  --shade-1: #d8d8d8;
  --box-shadow: none;
  --text-color: #414141;
  --box-shadow-inset: none;
  --settings-box-shadow: none;
  --box-shadow-gear: none;
  --box-shadow-gear-inset: none;
  --purple-shade-1: #8929ff9a;
  --box-shadow-settings-option: none;
  --color-hover: rgb(90, 90, 90);
  --options-color: #ececec;
  --icon-color-hover: #505050;
  --box-color: rgb(195, 195, 195);
  --title-color: #a5a5a5;
  --account-text-color: var(--title-color);
  
}

html[theme="dark"][neumorphism="false"]{
  --page-color: rgb(28, 28, 28);
  --shade-1: rgb(36, 36, 36);
  --box-shadow: none;
  --text-color: white;
  --box-shadow-inset: none;
  --box-shadow-gear: none;
  --box-shadow-gear-inset: none;
  --purple-shade-1: #ad6bff9a;
  --purple-shade-2: #3a22589a;
  --box-shadow-settings-option: none;
  --color-hover: rgba(255, 255, 255, 0.8);
  --options-color: #ececec;
  --icon-color-hover: #c5c5c5;
  --box-color: rgb(65, 65, 65);
  --title-color: #4b4b4b;
  --account-text-color: #bfbfbf;
}

body {
  font-family: 'Roboto Mono', monospace;
  background-color: var(--page-color);
}

.minibar-container{
  z-index: 3;
  position: fixed;
  right: 25px;
  bottom: 25px;
  width: 45px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.minibar-container > div{
  color: var(--icon-color);
  background: none;
  border: none;
}

.minibar-container > div:hover{
  color: var(--icon-color-hover);
  transition: color 0.2s;
}

.minibar-container > button{
  color: var(--icon-color);
  background: none;
  border: none;
}

.minibar-container > button:hover{
  color: var(--icon-color-hover);
  transition: color 0.2s;
}

.title{
  height: 150px;
  line-height: 50%;
  font-size: 70px;
  display: flex;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 900;
  color: var(--title-color);
}

@media screen and (max-width: 670px){
  .title > p{
    font-size: 30px;
  }
  .title{
    margin-left: -20px;
    margin-top: -20px;
  }
  :root{
    --typing-box-width: 298px;
    --typing-box-height: 200px;
    --text-font: 12px;
    --text-inner-padding: 13px;
  }
  .minibar-container{
    width: 35px;
    height: 100px;
    right: 10px;
    bottom: 10px;
  }

}

.coffee-container{
  margin-left: 100px;
}

.main-content{
  height: fit-content;
  /* height: 500px; */
  margin-top: 10vh;
  display: flex;
  flex-flow: column;
}

.typing-box{
  user-select: text;
  width: fit-content;
  height: fit-content;
  border-radius: 1.7%;
  background-color: var(--shade-1);
  box-shadow: var(--box-shadow);
  margin-bottom: 10px;
}

.typing-text{
  padding: var(--text-inner-padding);
  position: relative;
  width: calc(var(--typing-box-width) - 40px);
  height: auto;
  font-size: var(--text-font);
  color: var(--text-color);
}


.title-button{
  display: inline-block;
}

.title-button:link, .title-button:visited{
  margin-left: 50px;
  color: var(--icon-color);
}

.title-button:hover{
  color: var(--icon-color-hover);
  transition: color 0.2s;
}

a{
  color: var(--icon-color);
}
a:hover{
  color: var(--icon-color-hover);
  transition: color 0.2s;
}
a:link:active, a:visited:active{
  color: var(--icon-color);
}

.title-buttons-container{
  margin-left: 130px;
}


html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 100ms !important;
  transition-delay: 0 !important;
}

@media only screen and (min-width: 1700px){
  :root{
    --typing-box-width: 660px;
    --typing-box-height: 450px;
    --text-font: 25px;
    --text-inner-padding: 23px;
  }
  
}

@media only screen and (max-width: 1700px){
  .title{
    height: 130px;
    font-size: 55px;
  }
  .main-content{
    /* margin-top: 0px; */
    height: fit-content;
  }

  .title-buttons-container{
    margin-left: 80px;
  }
}

@media screen and (max-width: 760px){
  .title-buttons-container{
    display: none;
  }
}